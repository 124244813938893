/*
  Site container
*/

.container {
  margin-left: auto;
  margin-right: auto;
  width: 615px;
}

.content-container {
  margin-bottom: 4rem;
}

/*48em*/

@media (max-width: $container-responsive-width) {
  .container {
    width: 90vw;
  }
}

/*
  Header
*/

.header {
  padding-top: 1.2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 4rem;
  font-family: $sans-serif-font-family;
}

.header a:hover {
  color:black;
}

.header-title a {
  font-size: 2.1rem;
  text-decoration: none;
  color: $body-color;
}

.header-subtitle {
  color: $light-gray-color;
}

/*
  Menu
*/

.menu {
  padding-top: 0.5rem;
  display: block;
}

.menu-content {
  float: left;
}

.menu-content a {
  color: $light-gray-color;
  font-size: 1rem;
  padding-right: 10px;
}

.social-icons {
  float: right;
}

.social-icons a {
  color: $light-gray-color;
  font-size: 1rem;
}

@media (max-width: $elements-responsive-width) {
  .social-icons {
    float: left;
    padding-top: 0px;
  }
  .menu-content {
    margin-bottom: 5px;
  }
}

/*
  Footer
*/

.footer {
  color: $light-gray-color;
  text-align: center;
  margin: 2rem;
}

.footer a {
  color: $light-gray-color;
  padding: 10px;
  text-decoration: none;
}

.footer a:hover {
  color: $dark-gray-color;
}

.footer-description {
  display: block;
  margin-top:3px;
  margin-bottom: 1rem;
  color: $light-gray-color;
  font-family: $sans-serif-font-family;
  font-size:0.8rem;
}

dl {
  list-style-type: none;
}

// dl:before {
//   content: ' ';
//   background: #d4d9df;
//   display: inline-block;
//   position: absolute;
//   left: 29px;
//   width: 2px;
//   height: 100%;
//   z-index: 400;
// }

dl > dt:before {
  content: '';
  background: white;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  border: 3px solid #000000; /* was #22c0e8 */
  right: 10px;
  width: 5px;
  height: 5px;
  z-index: 400;
}

// dl >dt, dl > dd {
//   margin: 6pt 0; /* was 20px */
//   padding-left: 60px;
// }